//@ts-nocheck
import { signUp } from '@/api/auth';
import { checkPhoneNumber, getUserInfo, updateUser } from '@/api/user';
import { Button, StyledForm, Text } from '@/components/common';
import { DatePickerField, FormWrapper, PasswordField, PhoneField, TextField } from '@/components/form';
import SigninModal from '@/components/modal/SigninModal';
import VerificationPhoneCodeModal from '@/components/modal/VerificationPhoneCode';
import { ErrorMessage } from '@/components/modal/VerificationPhoneCode/styled';
import CheckPassword from '@/components/page/Register/CheckPassword';
import { firebaseLogin, sendVerificationCode } from '@/libs/firebase';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import userReducer from '@/redux/reducers/userReducer';
import { saveToken } from '@/utils/auth';
import { ERROR_CODES, UserType } from '@/utils/constant';
import { getUserTypeNumber } from '@/utils/helper';
import { ModalRenderer } from '@/utils/modalUtils';
import {
  BirthdateSchemaRegister,
  EmailSchema,
  FirstNameSchemaRegister,
  LastNameSchemaRegister,
  PasswordPolicySchema,
} from '@/utils/validation';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: EmailSchema,
  password: PasswordPolicySchema,
  first_name: FirstNameSchemaRegister,
  last_name: LastNameSchemaRegister,
  birth_date: BirthdateSchemaRegister,
});

const RegisterPage: React.FC<any> = () => {
  // const reCapchaContainerElement = useRef(null);
  const [errorSignup, setErrorSignup] = React.useState<string>('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formikRef = useRef();
  const location = useLocation();
  const userType = useAppSelector((state: any) => state.user.userType);
  const orderId = useAppSelector((state: any) => state.user.orderId);
  const [phoneCode, setPhoneCode] = useState('44');
  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const forceLogin = searchParams.get('forceLogin');
    if (forceLogin) {
      const step = searchParams.get('redirect');
      localStorage.setItem('kyc_step', step);
      onShowLoginPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onHandleSendVerificationCode = async (phoneNumber: string, userId?: string, isLogin = false) => {
    try {
      dispatch({ type: 'SHOW_LOADING' });
      if (phoneNumber) {
        await checkPhoneNumber(phoneNumber);
        const user = await updateUser(userId, { phone_number: phoneNumber });
        dispatch(userReducer.actions.setUserInfo(user));
      } else {
        const userInfo = await getUserInfo(userId);
        dispatch(userReducer.actions.setUserInfo(userInfo));
        phoneNumber = '+' + userInfo?.phone_number;
      }
      const isSendVerificationSuccess = await sendVerificationCode(phoneNumber);
      dispatch({ type: 'HIDE_LOADING' });
      if (isSendVerificationSuccess) {
        ModalRenderer.open({
          component: VerificationPhoneCodeModal,
          theme: 'white',
          inMobile: 'bottom',
          props: {
            phoneNumber: phoneNumber,
            isVerified: true,
          },
        });
      }
    } catch (error) {
      if (isLogin) {
        throw error;
      }
      if (error === ERROR_CODES.INVALID_PHONE_NUMBER || error === ERROR_CODES.EXISTING_PHONE_NUMBER) {
        setErrorSignup(t('ERROR_MESSAGE_INVALID_PHONE_NUMBER'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_REQUESTS || error === ERROR_CODES.TOO_MANY_REQUEST_CODE) {
        setErrorSignup(t('ERROR_MESSAGE_TOO_MANY_REQUESTS'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_ATTEMPS_CODE) {
        setErrorSignup(t('ERROR_MESSAGE_TOO_MANY_ATTEMPS_CODE'));
        return;
      }
      setErrorSignup(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    }
  };

  const handleAfterAuthSuccess = async (
    userId: string,
    accessToken: string,
    refreshToken: string,
    phoneNumber?: string,
    isLogin?: boolean,
  ) => {
    // save accessToken to localStorage
    saveToken(accessToken);
    // save refreshToken to cookie
    Cookies.set('refreshToken', refreshToken);
    // await getUser(userId);
    // if (userInfo?.phoneNumber) {
    //   const phoneNumber = userInfo?.phoneNumber;
    await onHandleSendVerificationCode(phoneNumber, userId, true);
    // } else {
    //   dispatch({ type: 'UPDATE_STATUS', payload: { create_account_passed: true } });
    //   dispatch({ type: 'NEXT_STEP' });
    //   dispatch({ type: 'SHOW_LOADING' });
    //   navigate('/verification-phone');
    // }
  };

  const onSubmit = async (values: any) => {
    // clearRecapchaContent();
    let payload = {
      email: values?.email,
      password: values?.password,
      phone: values?.phone,
      phoneCode: phoneCode,
      notifications: {
        terms: true,
        // marketing: checkMarketing,
      },
      licence: {
        first_name: values?.first_name,
        birthdate: moment(values?.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        last_name: values?.last_name,
      },
      source: getUserTypeNumber(userType),
    };
    if (userType === UserType.ADDITIONAL_DRIVER) {
      payload = { ...payload, order_id: orderId };
    }
    try {
      dispatch({ type: 'SHOW_LOADING' });
      const userInfo = await signUp(payload);
      if (!!userInfo) {
        const authInfo = await firebaseLogin(values.email, values.password);
        if (authInfo?.userId && authInfo?.accessToken && authInfo?.refreshToken) {
          await handleAfterAuthSuccess(
            authInfo?.userId,
            authInfo.accessToken,
            authInfo?.refreshToken,
            '+' + phoneCode + values.phone,
          );
        }
      }
    } catch (error: any) {
      //check login and popup verification phone
      // if (error === ERROR_CODES.INVALID_EMAIL || error === ERROR_CODES.EXISTING_EMAIL) {
      //   await onLogin(values.email, values.password);
      //   return;
      // }
      if (
        error === ERROR_CODES.USER_NOT_FOUND ||
        error === ERROR_CODES.INVALID_PASSWORD ||
        error === ERROR_CODES.USER_DISABLE ||
        error === ERROR_CODES.USER_DISABLED
      ) {
        formikRef.current.setFieldError('email', t('ERROR_MESSAGE_VALID_CREDENTIAL'));
        return;
      }
      if (error === ERROR_CODES.INVALID_PASSWORD) {
        setErrorSignup(t('ERROR_MESSAGE_INVALID_PASSWORD'));
        return;
      }
      if (error === ERROR_CODES.PRIVACY_NO_POLICY) {
        setErrorSignup(t('ERROR_MESSAGE_INVALID_POLICY'));
        return;
      }
      setErrorSignup(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };

  const onShowLoginPopup = () => {
    ModalRenderer.open({
      component: SigninModal,
      theme: 'white',
      inMobile: 'bottom',
      props: {
        isVerified: true,
        handleAfterAuthSuccess,
      },
    });
  };
  return (
    <FormWrapper>
      <Text color="darkPrimary" variant="title">
        {t('CREATE_AN_ACCOUNT')}
      </Text>
      <Box sx={{ mt: { md: 4, xxs: 0 } }}>
        <Text color="darkSecondary" variant="content">
          {t('HAVE_AN_ACCOUNT')}
          {'? '}
          <u style={{ cursor: 'pointer' }}>
            <span onClick={onShowLoginPopup}> {t('SIGN_IN')}</span>
          </u>
        </Text>
      </Box>
      <Box sx={{ mt: { xxl: 5, xxs: 4 } }}>
        <Text color="darkSecondary" variant="content" ƒ>
          {t('OR_SIGN_UP_EMAIL')}
        </Text>
      </Box>

      <Formik
        initialValues={{
          email: '',
          password: '',
          first_name: '',
          last_name: '',
          birth_date: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ errors, values, isValid }) => (
          <StyledForm>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xxl: 5, xxs: 4 }, mt: { xxl: 5, xxs: 4 } }}>
              {errorSignup ? <ErrorMessage>{errorSignup}</ErrorMessage> : null}

              {/* Email */}
              <Box>
                <TextField
                  type="email"
                  name="email"
                  config={{
                    variant: 'outlined',
                    label: t('EMAIL_ADDRESS'),
                    onClick: () => setErrorSignup(null),
                    InputProps: {
                      'data-testid': 'test_email',
                    },
                  }}
                />
              </Box>
              {/* Password */}
              <Box>
                <PasswordField
                  name="password"
                  config={{
                    variant: 'outlined',
                    label: t('PASSWORD'),
                    onClick: () => setErrorSignup(null),
                    InputProps: {
                      'data-testid': 'test_password',
                    },
                  }}
                />
                <Box>
                  {/* @ts-ignore */}
                  <CheckPassword newPassword={values?.password} />
                </Box>
              </Box>

              {/* Check password */}

              <Box>
                <PhoneField
                  type="tel"
                  name="phone"
                  config={{
                    label: t('PHONE_NUMBER'),
                    InputProps: {
                      'data-testid': 'test_phone',
                    },
                  }}
                  onHandlePhoneCode={setPhoneCode}
                  PhoneCode={phoneCode}
                  // formik={props}
                />
              </Box>
              <Box>
                <TextField
                  type="text"
                  name="first_name"
                  config={{
                    variant: 'outlined',
                    label: t('FIRST_NAME'),
                    maxLength: 255,
                    InputProps: {
                      'data-testid': 'test_first_name',
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField
                  type="text"
                  name="last_name"
                  config={{
                    variant: 'outlined',
                    label: t('LAST_NAME'),
                    maxLength: 255,
                    InputProps: {
                      'data-testid': 'test_last_name',
                    },
                  }}
                />
              </Box>
              <Box>
                <DatePickerField
                  name="birth_date"
                  config={{
                    variant: 'outlined',
                    placeholder: 'DD / MM / YYYY',
                    label: t('DATE_OF_BIRTH'),
                    inputProps: {
                      'data-testid': 'test_dob',
                    },
                  }}
                />
              </Box>
            </Box>
            <div>
              {/* {userType !== UserType.RENTAL_CARS ? (
                <FormMarginDense value="16">
                  <CheckboxTerm
                    onClick={() => {
                      setCheckMarketing((prev) => !prev);
                    }}
                    checked={checkMarketing}
                    text={t('KEEP_ME_UPDATED_NEW')}
                    testId="test_keep_updated"
                  />
                </FormMarginDense>
              ) : null} */}

              <Box sx={{ mt: { xxl: 5, xxs: 4 }, color: '#BBB' }}>
                <Trans
                  i18nKey="ACCEPT_TERM_AND_POLICY"
                  components={{
                    link1: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href="https://help.theout.com/hc/en-gb/articles/9948882884125-Terms-and-Conditions"
                        target="blank"
                      />
                    ),
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    link2: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a href="https://help.theout.com/hc/en-gb/articles/9949036554141-Privacy-Policy" target="blank" />
                    ),
                  }}
                />
              </Box>
              <Box sx={{ mt: { md: 8, xxs: 4 } }}>
                <Button
                  data-testid="test_create_account"
                  fullWidth
                  variant="contained"
                  color="white"
                  type="submit"
                  sx={{ textTransform: 'none' }}
                  disabled={
                    !(
                      isValid &&
                      !errorSignup &&
                      values.email &&
                      values.password &&
                      values.first_name &&
                      values.last_name &&
                      values.birth_date
                    )
                  }
                >
                  {t('CONTINUE')}
                </Button>
              </Box>
            </div>
          </StyledForm>
        )}
      </Formik>
      {/* <RecapchaContainer ref={reCapchaContainerElement}>
        <div id="recapcha-container" />
      </RecapchaContainer> */}
    </FormWrapper>
  );
};

export default RegisterPage;

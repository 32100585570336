//@ts-nocheck
import { getTokenOnfido, uploadLicence } from '@/api/user';
import { useAppDispatch } from '@/redux/hooks';
import { useNavigate } from 'react-router-dom';
import { updateUserSelfie, uploadPassport } from './../api/user';

export const useOnfido = (uid: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const [reloadUser] = useUser(uid);

  const nextStep = async (currentStep?: number = 0) => {
    try {
      // const requiredKYC = get(data, 'insurance.requires');
      const kycId = localStorage.getItem('kyc_id');
      const step = localStorage.getItem('kyc_step');
      window.location.href = `/kyc/${kycId}` + (step ? `/${step}` : '');
      return;
    } catch (error) {
      console.log(error);
      dispatch({ type: 'HIDE_LOADING' });
    }
  };
  const triggerLicence = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    const { token } = await getTokenOnfido(uid);
    const Onfido = await import('onfido-sdk-ui');
    dispatch({ type: 'HIDE_LOADING' });
    const onfido = Onfido.init({
      token: token,
      useModal: true,
      containerId: 'onfido-mount',
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido?.setOptions({ isModalOpen: false });
        onfido?.tearDown();
      },
      onComplete: async (data: any) => {
        if (data) {
          const frontID = data?.document_front?.id;
          const backID = data?.document_back?.id;
          onfido.setOptions({ isModalOpen: false });
          dispatch({ type: 'SHOW_LOADING' });
          uploadLicence({
            userId: uid,
            front: frontID,
            back: backID,
          })
            .then((res) => {
              dispatch({ type: 'user/updateUserLicence', payload: res.data });
              navigate('/verification-identity/confirm-licence');
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: 'HIDE_LOADING' });
            });
        }
      },
      onError: (error: any) => {
        console.log(error);
      },
      customUI: {
        borderRadiusSurfaceModal: '0',
        colorBorderSurfaceModal: '#4d4d4d',
        colorBorderLinkUnderline: 'transparent',
      },
      steps: [
        {
          type: 'document',
          options: {
            documentTypes: {
              driving_licence: {
                country: null,
              },
              national_identity_card: false,
              residence_permit: false,
              passport: false,
            },
          },
        },
      ],
    });
  };

  const triggerSelfie = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    const { token } = await getTokenOnfido(uid);
    const Onfido = await import('onfido-sdk-ui');
    dispatch({ type: 'HIDE_LOADING' });
    const onfido = Onfido.init({
      token: token,
      useModal: true,
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido?.setOptions({ isModalOpen: false });
        onfido?.tearDown();
      },
      onComplete: async (data) => {
        if (data) {
          const selfieID = data?.face?.id;
          const variant = data?.face?.variant;
          onfido.setOptions({ isModalOpen: false });
          dispatch({ type: 'SHOW_LOADING' });
          updateUserSelfie(uid, variant, selfieID)
            .then((res) => {
              nextStep(2);
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: 'HIDE_LOADING' });
            });
        }
      },
      onError: (error) => {
        console.log(error);
      },
      customUI: {
        borderRadiusSurfaceModal: '0',
        colorBorderSurfaceModal: '#4d4d4d',
        colorBorderLinkUnderline: 'transparent',
        colorContentBody: '#9f9f9f',
      },
      steps: [
        {
          type: 'face',
          options: {
            requestedVariant: 'video',
          },
        },
      ],
    });
  };

  const triggerPassport = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    const { token } = await getTokenOnfido(uid);
    const Onfido = await import('onfido-sdk-ui');
    dispatch({ type: 'HIDE_LOADING' });
    const onfido = Onfido.init({
      token: token,
      useModal: true,
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido?.setOptions({ isModalOpen: false });
        onfido?.tearDown();
      },
      onComplete: async (data) => {
        if (data) {
          const passport = data?.document_front?.id;
          onfido.setOptions({ isModalOpen: false });
          dispatch({ type: 'SHOW_LOADING' });
          uploadPassport({
            userId: uid,
            passport: passport,
          })
            .then((res) => {
              nextStep(4);
            })
            .catch((error) => {
              if (['DVLA', 'BVRLA', 'Onfido', 'UserTooYoung'].includes(error)) {
                dispatch({ type: 'NEXT_STEP_AUTO' });
                dispatch({
                  type: 'UPDATE_STATUS',
                  payload: { verify_identity_passed: true },
                });
                return;
              }
              dispatch({ type: 'HIDE_LOADING' });
            });
        }
      },
      onError: (error) => {
        console.log(error);
      },
      customUI: {
        borderRadiusSurfaceModal: '0',
        colorBorderSurfaceModal: '#4d4d4d',
        colorBorderLinkUnderline: 'transparent',
      },
      steps: [
        {
          type: 'document',
          options: {
            documentTypes: {
              national_identity_card: false,
              residence_permit: false,
              passport: true,
            },
          },
        },
      ],
    });
  };

  return [triggerLicence, triggerSelfie, triggerPassport, nextStep];
};

import React, { useEffect } from 'react';
import { Wrapper } from './styles';

const SessionValidatePage: React.FC<any> = () => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Wrapper />;
};

export default SessionValidatePage;

import { Text } from '@/components/common';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#dddcd8',
  backgroundSize: 'contain',
  backgroundPosition: 'bottom',
  backgroundImage:
    'url(/images/car/checkin-lights-375.png), url(https://static.theout.com/checkin/homepage/checkin-375.jpg)',
  [theme.breakpoints.up('sm')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-540.png), url(https://static.theout.com/checkin/homepage/checkin-540.jpg),',
    minHeight: '1000px',
  },
  [theme.breakpoints.up('md')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-768.png), url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1050px',
  },
  [theme.breakpoints.up('1000')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-768.png), url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1100px',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage:
      'url(/images/car/checkin-lights-1024.png), url(https://static.theout.com/checkin/homepage/checkin-1024.jpg)',
    minHeight: '700px',
  },
  [theme.breakpoints.up('xl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1280.png), url(https://static.theout.com/checkin/homepage/checkin-1280.jpg)',
  },
  [theme.breakpoints.up('xxl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1440.png), url(https://static.theout.com/checkin/homepage/checkin-1440.jpg)',
  },
  [theme.breakpoints.up('xxxl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1920.png), url(https://static.theout.com/checkin/homepage/checkin-1920.jpg)',
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  marginTop: '24px',
  fontSize: '24px',
  fontWeight: '500',
  marginBottom: 0,
  textAlign: 'center',
  whiteSpace: 'pre-line',
  textTransform: 'uppercase',
}));

export const SubTitle = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '15px',
  marginTop: '24px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: '121px',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    marginTop: '48px',
  },
  button: {
    width: '144px',
  },
}));

export const Logo = styled(Text)(({ theme }) => ({
  marginTop: '97px',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    marginTop: '0',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '32px',
  },
}));

export const ErrorDetail = styled(Text)(({ theme }) => ({
  margin: '0 23.5px',
  [theme.breakpoints.up('sm')]: {
    margin: '0',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 96px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 32px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 12px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 44px',
  },
}));
